// German
export const locale = {
  lang: 'de',
  data: {
    TRANSLATOR: {
      SELECT: 'Wählen Sie Ihre Sprache',
    },
    MENU: {
      NEW: 'Neu',
      ACTIONS: 'Aktionen',
      CREATE_POST: 'Erstellen Sie einen neuen Beitrag',
      PAGES: 'Seiten',
      FEATURES: 'Eigenschaften',
      APPS: 'Apps',
      PROFILE: 'Profil',
	  ARCHIVE: 'Archiv',
      LIVEVIEW: 'Live-Ansicht',
	  MY_ARCHIVE: 'Mein Archiv',
      OTHER_USER: 'Anderer Benutzer',
      LIVE_VIEW: 'Live-Ansicht',
      AMPLITUDE_OVERVIEW: 'Amplituden',
      RAW_CHART: 'Rohsignal',
      RATIO_CHART: 'Ratios',
      CUSTOM_LIVE_VIEW: 'Individuelle Live-Ansicht',
      DEVICE_OVERVIEW: 'Geräteübersicht',
      CHARTS: 'Diagramme',
      TRAINING_CONFIGURATION : 'Trainings-Konfiguration',
      TRAINING: 'Training',
      TRAINING_SCREEN: 'Trainingsbildschirm',
      REMOTE_TRAINING: 'Remote Training',
      ANALYSIS: 'Analyse',
      ANALYSIS_SCREEN: 'Analysebildschirm',
      RECORDINGS: 'Aufzeichnung(en) auswählen',
      TEST: 'Testung',
	  TEST_SCREEN: 'Testbildschirm',
      TEST_CONFIGURATION: 'Test-Konfiguration',
      BLUETOOTH: 'Bluetooth®',
      STATISTIC: 'Statistik',
      DASHBOARD: 'Übersicht',
	  UPDATE_TEXT: 'Training aktualisiert'
    },
    AUTH: {
      ASIDE: {
        WELCOME_TEXT: 'Willkommen bei der eSense EEGenius \n Web App',
        DATA_PRIVACY: 'Datenschutz',
        ABOUT: 'Über',
        CONTACT: 'Kontakt',
        LEGAL: 'Rechtliches',
        PRIVACY: 'Datenschutz'
      },
      GENERAL: {
        SIGN_OUT: 'Ausloggen',
        OR: 'Oder',
        SUBMIT_BUTTON: 'KOSTENLOSES KONTO ERSTELLEN',
        CREATE_ACCOUNT: 'Kostenloses Konto erstellen',
        NO_ACCOUNT: 'Haben Sie kein Konto?',
        SIGNUP_BUTTON: 'Anmelden',
        FORGOT_BUTTON: 'Passwort vergessen',
        BACK_BUTTON: 'Zurück',
        PRIVACY: 'Datenschutz',
        LEGAL: 'Impressum',
        CONTACT: 'Kontakt',
        RESET_PASSWORD: 'Ihr Passwort zurücksetzen',
        ACCOUNT_FEATURES: 'Erläuterung der Kontomerkmale',
        NEW_DASHBOARD: 'Das neue eSense Dashboard'
      },
      LOGIN: {
        TITLE: 'Anmeldung bei Ihrem Konto',
        LOGIN_BUTTON: 'LOGIN',
        LOGIN_GOOGLE: 'MIT GOOGLE+ REGISTRIEREN & ANMELDEN',
        LOGIN_APPLE: 'Weiter mit Apple',
        BUTTON: 'Einloggen',
        APPLE_LINK_TEXT: 'Die App funktioniert auf iPads. Bitte laden Sie unsere iOS App "eSense EEGenius" herunter.'
      },
      FORGOT: {
        TITLE: 'Passwort vergessen?',
        DESC: 'Bitte geben Sie Ihre E-Mail Adresse ein, um das Passwort zurückzusetzen.',
        SUCCESS: 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
		FAILURE: 'Entschuldigung, versuchen Sie es noch einmal.'
      },
      REGISTER: {
        TITLE: 'Anmelden',
        DESC: 'Geben Sie Ihre Daten ein, um Ihr Konto zu erstellen:',
        SUCCESS: 'Ihr Account wurde erfolgreich registriert.',
        BACK_TO_LOGIN: 'Zurück zur Anmeldung',
        BACK: 'Zurück',
        REGISTRATION: 'Anmeldung',
        AGREE: 'Ich stimme den',
        TERMS: 'Bedingungen und Konditionen zu.',
        CANCEL: 'Abbrechen',
        YOUR_DETAILS: 'Meine Angaben',
        YOUR_SUPERVISOR: 'Mein Supervisor',
        CHOOSE_SUPERVISOR: 'Supervisor wählen',
        I_IM_SUPERVISOR: 'Ich bin selbst Supervisor, hier ist mein Code',
        AGE: 'Alter',
        SUPERVISOR_FROM_LIST: 'Bitte wählen Sie Ihren Supervisor',
        SUPERVISED_TEXT: 'Ich werde von Mindfield betreut',
        SUPERVISOR_HINT: 'Der Supervisor erhält eine E-Mail und muss Ihre Anmeldung akzeptieren!',
        WRONG_SUPERVISOR_CODE: 'Falscher Code',
        FAILURE: 'Bei der Registrierung ist ein Fehler aufgetreten, bitte versuchen Sie es erneut'
      },
      INPUT: {
        EMAIL: 'E-Mail',
        FULLNAME: 'Ihr Name',
        PASSWORD: 'Passwort',
        CONFIRM_PASSWORD: 'Passwort bestätigen',
        USERNAME: 'Benutzername',
      },
      VALIDATION: {
        INVALID: '{{name}} ist nicht gültig',
        REQUIRED: '{{name}} ist benötigt',
        MIN_LENGTH: '{{name}} die Mindestlänge beträgt {{min}}',
        AGREEMENT_REQUIRED: 'Bitte akzeptieren Sie unsere Nutzungsbedingungen',
        NOT_FOUND: 'Der eingegebene {{name}} wurde nicht gefunden.',
        INVALID_LOGIN: 'Es gibt keinen Benutzerdatensatz, der dieser E-Mail entspricht. Der Benutzer wurde möglicherweise gelöscht.',
        REQUIRED_FIELD: 'Benötigtes Eingabefeld',
        MIN_LENGTH_FIELD: 'Mindestlänge:',
        MAX_LENGTH_FIELD: 'Maximale Länge:',
        INVALID_FIELD: 'Der Wert ist nicht gültig.',
        USER_NOT_FOUND: 'Benutzer nicht gefunden!',
        EMAIL_INVALID: 'E-Mail Adresse ist ungültig!',
        PASSWORD_INVALID: 'Ungültiges Passwort!',
        ALREADY_EXISTS: 'Ein Benutzer mit dieser E-Mail existiert bereits.'
      },
    },
    PROFILE: {
      PROFILE: {
        PROFILE: 'Profil',
        PERSONAL_INFO: 'Benutzerdaten',
        ALLOWED_SUPERVISORS: 'Optional: Einen weiteren Supervisor hinzufügen',
        ACCESS_DATA: '(mit Zugriff auf meine Daten)',
		ID: 'ID',
        USER_ROLE: 'Benutzerrolle',
        NAME: 'Name',
        GENDER: 'Geschlecht',
        MALE: 'Männlich',
        FEMALE: 'Weiblich',
        OTHER: 'Andere',
		AGE: 'Alter',
        EMAIL: 'E-Mail',
        UPDATE: 'Änderungen speichern',
        STATS: 'Statistik',
        SESSIONS: 'Aufzeichnungen',
        DAYS_OF_USAGE: 'Tage in Benutzung',
        CREATED_AT: 'Erstellt am',
        GROUPS: 'Gruppen',
        PLANS: 'Pläne',
        SUPERVISOR_DESC: 'Sie sind ein Supervisor, dies enthält alle Funktionen des Premium-Plans.',
        ABOUT_BASIC: 'Speichern Sie alle Ihre Aufzeichnungen lokal und online in der Cloud. Greifen Sie von allen Ihren Geräten aus auf Ihre Aufzeichnungen zu. Erhalten Sie Zugriff auf dieses eSense Web Dashboard',
        ABOUT_PREMIUM: 'Speichern Sie alle Ihre Aufzeichnungen + Prozeduren lokal und online in der Cloud. Greifen Sie von allen Ihren Geräten aus auf Ihre Aufzeichnungen + Prozeduren zu. Teilen Sie Ihre Aufzeichnungen mit anderen Benutzern. Erhalten Sie Zugriff auf dieses eSense Web Dashboard inkl. Live-Streaming.',
        PRICE_RANGE: 'Preisbereich:',
        PREMIUM_PLAN: 'Premium Plan',
        BASIC_PLAN: 'Basis Plan',
        SUBSCRIBED_AT: 'Beginnt am',
        ENDS_AT: 'Endet am',
        LANGUAGE: 'Bevorzugte Sprache für die Kommunikation',
        LANGUAGE_SELECTOR_EN: 'Englisch',
        LANGUAGE_SELECTOR_DE: 'Deutsch',
        SELECT_IMAGE: 'Bild auswählen',
        PAGES_COACHMARKS: 'Hilfe anzeigen',
        DELETE_ACCOUNT: 'Konto löschen',
        VIDEO_CALL: 'Direkter Videoanruf',
        GROUP_CALL: 'Treffen der Gruppe',
        STATUS_ONLINE: 'Online',
        STATUS_OFFILE: 'Offline',
        STATUS_UNKNOWN: 'Unbekannt',
        LAST_SEEN: 'Zuletzt gesehen:',
        USER_MANAGEMENT: 'Benutzerverwaltung',
        COPY_LINK: 'Dies ist ihr Link zur Registrierung von neuen Klienten, wobei Sie als Supervisor direkt vorausgewählt sind:',
        COPY_LINK_MESSAGE: 'Link erfolgreich erstellt',
        SUPERVISORS_ARROW_TEXT: 'Verwalten Sie',
        SURVEY_DATA: 'Info zu den Zielen',
        NO_GOALS: 'Der Benutzer hat seine Ziele noch nicht festgelegt.',
        INVITE_LINK: 'Oder laden Sie den Benutzer per E-Mail ein:',
        INVITE_LINK_CONFIRM: 'Einladungslink an den Benutzer gesendet',
        INVITE_LINK_ERROR: 'Dieser Benutzer ist nicht vorhanden',
        APPROVAL_LIST: 'Benutzer, die auf die Genehmigung warten',
        COPY_TO_CLIPBOARD: 'Link in die Zwischenablage kopieren',
        SUPERVISOR_PENDING: 'Noch nicht akzeptierte Benutzer',
        PROFILE_UPDATED: 'Profil aktualisiert',
        SURVEY_HEADLINE: 'Fragebogen (Optional für Supervisoren)',
        SUPERVISORS_TREE: 'Supervisoren & Benutzer',
        SUPERVISORS_ACTIVITY: 'Supervisor Tätigkeit',
        ADD_SUPERVISOR: '',
        SUPERVISOR_ID: 'Eingabe Supervisor ID',
        SUPERVISOR_ERROR: 'Dieser Supervisor ist nicht vorhanden',
        SELF_ID_ERROR: 'Sie können sich nicht selbnst in diese Liste eintragen',
        SUPERVISOR_ADDED: 'Liste der Supervisoren aktualisiert',
        ADD_TEXT: 'Anfrage absenden',
        SUPERVISOR_EXISTS: 'Fehler, dieser supervisor ist bereits in der Liste',
        CONNECTED_DEVICES: 'Angeschlossene Geräte:',
        CHAT_TEMPLATES: 'Chat-Vorlagen',
        STORAGE_USAGE: 'Speicher für hochgeladene Videos:',
        USED: 'Benutzung',
        REMAINING: 'Verfügbar',
        USER_STORAGE_CHART: 'Benutzer-Speichertabelle',
        OF_TEXT: 'von',
        ALLOWED_USERS_USED: 'Benutzer',
        LAST_LOGIN: 'Letzte Anmeldung',
        USER_NAME: 'Benutzername',
        SUPERVISORS_LIST: 'Ihr(e) Supervisor(en):'
      },
      NEWS: {
        NEWS: 'News',
        CREATE_NEWS: 'Erstelle eine News',
        TITLE: 'Titel',
        CANCEL: 'Abbrechen',
        CREATE: 'Erstellen',
        CONTENT: 'Inhalt',
      },
      BILLING: {
        BILLING: 'Rechnungsstellung',
        HARDWARE_INFO:'Hardware-Informationen (optional)',
        PLATFORM: 'Plattform',
        VERSION: 'Plattform Version',
        BROWSER_VERSION: 'Version des Chrome-Browsers',
        UPDATE_INFO: 'Informationen aktualisieren',
        HARDWARE_INFO_UPDATED: 'Hardware-Informationen aktualisiert',
        ADD_HARDWARE_INFO_TEXT: 'Bitte fügen Sie Ihre Hardware-Informationen hinzu, um uns Einblicke zu geben, die uns helfen, die Stabilität zu verbessern und Fehler schneller zu beheben.',
        OPEN_IN_NEW_TAB: 'In neuer Registerkarte öffnen'
      },
      STATISTICS: {
        STATISTICS: 'Statistiken',
        LAST_LOGIN: 'Letzte Anmeldung',
        DISPLAY_NAME: 'Name des Benutzers',
        MY_STATS: 'Meine Statistik'
      },
      QUICK_ANSWERS: {
        QUICK_ANSWERS: 'Schnelle Antworten',
        CHAT_TEMPLATES: 'Chat Vorlagen',
        SUCCESS_DELETED: 'Erfolgreich gelöscht',
        SUCCESS_UPDATED: 'Erfolgreich aktualisiert',
        TEXT: 'Text',
        TITLE: 'Titel',
        EDIT: 'Bearbeiten',
        DELETE: 'Löschen',
        ADD_QUICK_ANSWERS: 'Schnelle Antwort hinzufügen',
        SUCCESS_ADD: 'Erfolgreich hinzugefügt',
      },
      EMAIL : {
        EMAIL: 'E-Mail'
      }
    },
    ARCHIVE: {
      RECORDINGS: {
        RECORDINGS: 'Aufzeichnungen',
		SHARED_RECORDS: 'Geteilte Aufzeichnungen',
        RECORDING_NAME: 'NAME MESSUNG',
        DATE: 'DATUM (SS:MM:SS)',
        TIME: 'ZEIT (SS:MM:SS)',
        TIME_INCREASE: 'ZUNAHME (SS:MM:SS)',
        TIME_STEADY: 'STETIG (SS:MM:SS)',
        TIME_DECREASE: 'TABNAHME (SS:MM:SS)',
        MAXIMUM: 'MAXIMUM',
        MINIMUM: 'MINIMUM',
        AVERAGE_BREATH_PER_MINUTE: 'DURCHSCHNITTLICHER ATEMZUG PRO MINUTE',
        DIFFERENCE: 'DIFFERENZ MIN/MAX',
        TOTAL_SCR: 'GESAMT SCR',
        SCR_OF_SESSION: '% SCR DER SITZUNG',
        ELECTRODE_FIXATION: 'ELECTRODE FIXATION',
        STRESS_INDEX: 'STRESS INDEX',
        AVERAGE_HR: 'DURCHSCHNITTLICH HR',
        AVERAGE_RR: 'DURCHSCHNITTLICH RR',
        CORRECTED_HR_VALUES: 'Korrigierte Werte zähen',
        CORRECTED_HR_VALUES_IN_PERCENTAGE: 'Korrigierte Werte Prozent',
        COHERENCE: 'KOHÄRENZ',
        BREATH_PER_MINUTE: 'ATEMZÜGE PRO MINUTE',
		AVERAGE_OF_SESSION: 'DURCHSCHNITT SESSION',
		TEMPERATURE_CURRENT: 'AKTUELL',
        MAXIMUM_30S: 'ATEMTIEFE',
        TEMPERATURE_MAXIMUM: 'MAXIMUM',
        TEMPERATURE_MINIMUM: 'MINIMUM',
        TEMPERATURE_DIFFERENCE: 'DIFFERENZ MIN/MAX',
        AVERAGE_VALUE: 'DURCHSCHNITT SESSION',
        INCREASE_TIME: 'ZUNAHME (SS:MM:SS)',
        DECREASE_TIME: 'ABNAHME (SS:MM:SS)',
        STEADY_TIME: 'STETIG (SS:MM:SS)',
        INCREASE_TIME_HRV: 'Zunahme der HRV Amplitude',
        STEADY_TIME_HRV: 'Stetige HRV Amplitude',
        DECREASE_TIME_HRV: 'Abnahme der HRV Amplitude',
        SCR_LOW: '0-5 SCR/MIN (SS:MM:SS)',
        SCR_AVG: '6-9 SCR/MIN (SS:MM:SS)',
        SCR_HIGH: '10-15 SCR/MIN (SS:MM:SS)',
        SCR_MAX: '>16 SCR/MIN (SS:MM:SS)',
        SCR_MIN_VALUE: 'SCR/MIN',
        SCR_COVERAGE: '% SCR DER SITZUNG',
        SCR_TOTAL: 'GESAMT SCR',
        SCR: 'SCR',
        CURRENT_CALCULATED_RA: 'BERECHNETE ATEMAMPLITUDE',
        AVERAGE_REGULARITY: 'DURCHSCHNITT GLEICHMÄßIGKEIT',
        HOW_DO_YOU_FEEL: 'WIE FÜHLEN SIE SICH?',
        HEARTS: 'HERZEN',
        NO_RECORDINGS: 'Keine Aufzeichnungen',
		SCORE: 'ERGEBNIS',
		USER_ID: 'Benutzer ID oder E-Mail',
        DEPTH_OF_BREATH: 'ATEMTIEFE',
      },
      ANALYZE: {
        ANALYZE: 'Analysieren',
        NO_ANALYZE: 'Keine Analyse',
        NO_CHART_SELECTED: 'Kein Diagramm ausgewählt',
        CHOOSE_A_DATE: 'Wähle ein Datum'
      },
      SHARED_RECORDS: {
        SHARED_RECORDS: 'Freigegebene Aufzeichnungen anderer Benutzer',
        NO_SHARED_RECORDS: 'Keine Aufzeichnungen anderer Benutzer',
      },
    },
    OTHER_USER: {
      USER_ID: 'Benutzer ID oder E-Mail',
      SEARCH_FOR_IDEMAIL: ' Suchen nach ID/E-mail',
    },
    LIVE_VIEW: {
      NO_LIVE_VIEW: 'Keine Live-Ansicht',
      TIME: 'Zeit (SS:MM:SS)',
      MAXIMUM: 'Maximum',
      MINIMUM: 'Minimum',
      BREATH_PER_MINUTE: 'Atemzug/Min',
      DIFFERENCE: 'Differenz Min/Max',
      COHERENCE: 'Kohärenz',
      BREATHING: 'Atmung',
      AVERAGE_OF_SESSION: 'Durchschnitt der Sitzung',
      CURRENT_VALUE: 'Aktueller Wert',
      REGULARITY: 'Gleichmäßigkeit',
      CURRENT_CALCULATED_RA: 'Berechnete Atemamplitude',
    },
    CUSTOM_CHARTS: {
      USER_ID_OR_EMAIL: 'Benutzer ID oder E-Mail',
      ADD_CUSTOM_CHART: 'Indviduellen Chart hinzufügen',
      TYPE: 'Typ',
      SAVE_CUSTOM_PATTERN: 'Speichere Layout'
    },
    USER_PROFILE: {
      USER_PROFILE: 'Benutzerprofil',
      USER_ROLE: 'Benutzerrolle',
    },
    GENIUS_SETTINGS: {
      CONNECT_TEXT: 'Verbinden',
      DISCONNECT_TEXT: 'Trennen',
      HOW_TO_PLACE: 'Vorschlag zur Elektrodenplatzierung',
      CHANNEL_TEXT: 'Impedanz:',
      NEGATIVE_IMPEDANCE: 'Es sind bei Ihnen negative Impedanzwerte aufgetreten. Dies passiert äußerst selten, wenn zu viel NaCl Lösung verwendet wurde. Diese kann eine Salzbrücke entstehen lassen, wenn eine Verbindung zwischen Vorder- und Rückseite der Elektrode entsteht. Bitte nehmen Sie die Elektrode vom Kopf, trocken Sie überschüssiges NaCl und platzieren Sie die Elektrode erneut. Sollten die negativen Impedanzen (häufig -4,5, welches ein Übersteuern des EEG Verstärkers signalisiert) nicht verschwunden sein, ersetzen Sie die Elektrode gegen eine Neue. Lassen Sie die betroffene Elektrode komplett trocknen und verwenden Sie beim nächsten Mal weniger NaCl. Ggf. müssen Sie dies mit allen drei Elektroden machen und diese überprüfen, um herauszufinden welche Elektrode die negativen Impedanzen hervorruft.',
      PERSONAL_DEVICE: 'Persönlicher Sensor:',
      PERSONAL_DEVICE_ERROR: 'Der aktuelle eSense EEGenius Sensor, den Sie verbinden möchten, kann nicht mit Ihrem Konto verknüpft werden. Es ist entweder bereits ein Sensor mit Ihrem Konto verknüpft und/oder Sie haben keine weiteren Plätze für weitere Sensoren in diesem Account mehr verfügbar.',
      BIND_SENSOR_TEXT: 'Wir haben festgestellt, dass Sie Ihren eSense EEGenius Sensor noch nicht mit Ihrem Konto verknüpft haben. Um die Anwendung nutzen zu können, müssen Sie den Sensor verknüpfen. Bitte bestätigen Sie die Verknüpfung.',
      CONFIRM_BIND: 'Verknüpfung bestätigen',
      CANCEL_BIND: 'Abbrechen',
      BIND_SENSOR_ERROR: 'Sie können dieses Gerät nicht verwenden, da die maximal zulässige Anzahl von Benutzern überschritten wurde.'
    },
    RECORD_ACTIONS: {
      ACTIVITY: 'Klicken Sie den roten Knopf, um Ihre Messung aufzuzeichnen:',
      PRESET_LENGTH: 'Voreingestellte Länge (stoppt automatisch)',
      MIN: 'min'
    },
    INFO_POPUP: {
      HOW_TO_USE: 'Bitte platzieren Sie die Elektroden wie folgt:',
      LEFT: 'Links',
      CENTER: 'Mitte',
      RIGHT: 'Rechts'
    },
    CONFIRM_DELETE_CONFIGURATION: {
      SURE_TEXT: 'Sind Sie sicher?',
      CONFIRM: 'Ja, bestätigen',
      CLOSE: 'Nein, abbrechen'
    },
    TEST_CONFIGURATION: {
      HEADING: 'Test-Konfiguration',
      PART_TEXT: 'Abschnitt',
      SAVED_TEMPLATES : 'Wählen Sie eine Vorlage oder erstellen Sie eine neue',
      CREATE_NEW: 'Neu erstellen',
      LENGTH: 'Länge',
      ENABLED: 'Aktiviert',
      OPTION: 'Text',
      SITUATION_NAME: 'Name des Tests',
      SAVE_TEXT: 'Speichern',
      PLAY_AUDIO: 'Klang und Ton',
      CHECK_AUDIO: 'Testton',
      START_PROCEDURE: 'Test Starten',
      SELECT_IMAGE: 'Bild',
      SELECT_TONE: 'Ton wählen',
      HINT_TEXT: 'Wenn das Kontrollkästchen aktiviert ist, wird am Ende dieses Teils ein Ton abgespielt.',
      AUDIO_ERROR: 'Unterstützte Audioformate: mp3, waw, oog',
      IMAGE_ERROR: 'Nicht unterstütztes Bildformat',
      CUSTOM_TEMPLATES: 'Benutzerdefinierte Vorlagen',
      ASSIGN_TEXT: 'Weisen Sie diese Konfiguration einem oder mehreren Benutzern zu, indem Sie ein Häkchen bei einem oder mehreren Benutzern machen. Die Nutzer werden dann per E-Mail informiert. (Wenn Sie ein Häkchen bei einem Nutzer wieder entfernen, wird dieses Training dem Nutzer wieder entzogen und steht nicht mehr zur Wahl. Der Nutzer wird auch darüber per E-Mail informiert.) Bitte speichern oder updaten Sie dann die Konfiguration.',
      TEMPLATE_USER_ID: 'ID oder E-Mail',
      UPDATE_TRAINING_SUCCESS: 'Test aktualisiert',
      ALREADY_EXISTS: 'Dieser Test ist bereits vorhanden, bitte wählen Sie einen anderen Namen',
      TEMPLATE_ADDED: 'Testvorlage erfolgreich erstellt',
      ALL_TEMPLATES: 'Alle Vorlagen',
      NO_TRAININGS_FOR_USER: 'Für diesen Benutzer gibt es keine Vorlagen',
      ASSIGNED_USERS_FILTER: 'Filter für Testvorlagen, die einem Benutzer zugeordnet sind:'
    },
    TEST_CONFIG_DIALOG: {
      HEADING: 'Liste der Textvorlagen',
      ADD_TEXT: 'Hinzufügen',
      CLOSE: 'Schließen'
    },
    TRAINING_CONFIGURATION: {
      HEADING: 'Trainings-Konfiguration',
      TEMPLATES_TEXT: 'Wählen Sie eine Vorlage oder erstellen Sie eine neue',
      SELECT_TEXT: 'Aktiv',
      TOPIC: 'Untertitel',
      LENGTH: 'Länge',
      MIN: 'min',
      PROTOCOL: 'Protokoll',
      FREQUENCY_ONE: '1. Frequenzband',
      FREQUENCY_TWO: '2. Frequenzband',
      THRESHOLD_TYPE: 'Schwellenwerttyp',
      YOUTUBE_PLAYLIST: 'Video',
      NOTE: 'Hinweis',
      CLOSE_DIALOG: 'Abbrechen',
      SAVE_DIALOG: 'Speichern',
      YOUTUBE_LINK: 'Wiedergabeliste URL oder Video URL',
      PLAYLIST_NAME: 'Name der Wiedergabeliste oder des Videos',
      PLAYLIST_HISTORY: 'Wiedergabelisten oder Videos im Verlauf',
      YOUTUBE_INVALID_LINK: 'Ungültige Wiedergabelisten-URL',
      GET_PLAYLIST: 'URL abrufen',
      PLAYLIST_ADDED: 'Wiedergabeliste oder Video hinzugefügt',
      TEMPLATE_NAME: 'Name des Trainings',
      SAVE_BUTTON: 'Speichern',
      DELETE_TEXT: 'Sind Sie sicher, dass Sie diese Vorlage löschen möchten?',
      CONFIRM_DELETE: 'Ja, bestätigen',
      CLOSE: 'Nein, abbrechen',
	    INVALID_USER: 'Ungültige Benutzer-ID oder E-Mail',
      ASSIGN_TEXT: 'Weisen Sie diesem Training einen Benutzer zu und speichern Sie!',
      TEMPLATE_USER_ID: 'Benutzer-ID oder E-Mail',
      ASSIGNED_USERS_TEXT: 'Zugewiesene Benutzer',
      UPDATE_USERS_BUTTON: 'Update',
      UPDATE_TEXT: 'Training aktualisiert',
      ALREADY_EXISTS: 'Dieses Training ist bereits vorhanden, bitte wählen Sie einen anderen Namen',
      TEMPLATE_ADDED: 'Trainingsvorlage erfolgreich erstellt',
      TEMPLATE_CATEGORY: 'Hauptkategorie im Fragebogen',
	    TEMPLATE_SUBCATEGORY: 'Unterkategorie im Fragebogen',
      CLEAR_SELECTED: 'Auswahl löschen',
      ASSIGNED_USERS_FILTER: 'Filter für Trainingsvorlagen, die einem Benutzer zugeordnet sind:',
      CUSTOM_TEXT: 'Text des Untertitels (optional)',
      VISIBILITY: 'Sichtbarkeit',
      CHART_VISIBILITY: 'Kurve',
      BAR_VISIBILITY: 'Bar',
      VIDEO_VISIBILITY: 'Video',
      VIDEO_SPEED: 'Geschwindigkeit',
      SELECT_ERROR: 'Sie benötigen mindestens einen aktiven Teil',
      LINK_TOOLTIP: 'Hier können Sie eine URL zu einer YouTube-Video-Wiedergabeliste oder einem einzelnen YouTube-Video einfügen.',
      NAME_TOOLTIP: 'Geben Sie Ihrer YouTube-Wiedergabeliste oder Ihrem YouTube-Video einen Namen. Nach der Bestätigung wird diese Wiedergabeliste oder dieses Video im Verlauf gespeichert, den Sie in Zukunft wieder verwenden können.',
      OPEN_TEXT: 'YouTube öffnen',
      VIDEO_CHOOSE_HEADING: 'Neue Wiedergabeliste oder Video von YouTube oder benutzerdefinierte Videodateien hinzufügen',
      VIDEO_CHOOSE_CUSTOM: 'Ein neues Video hinzufügen:',
      YOUTUBE_INVALID_LINK_URL: 'Ungültige Link-URL'
    },
    TRAINING_ICONS : {
      FOCUS: 'Fokus',
      CONCENTRATION: 'Konzentration',
      CALM: 'Beruhigung',
      RELAXATION: 'Entspannung',
      MEMORY: 'Gedächtnis',
      THINKING: 'Nachdenken',
      LEARNING: 'Lernen',
      SPEED_UP: 'Beschleunigen',
      SLOW_DOWN: 'Verlangsamen',
      TUNING: 'Tuning'
    },
    TRAINER_TEMPLATE: {
      START: 'Start',
      STOP: 'Stop',
      NOTE_TEXT: 'Das Training wird für Ihre heutige Tagesform optimiert.',
      PAUSED: 'Pausiert',
      RESUME: 'Wiederaufnehmen',
      TRAINING_PART: 'Trainings Abschnitt:',
      TOTAL_TIME: 'Gesamtzeit:',
      PAUSE_BY_ERRORS: 'Wir haben festgestellt, dass die Verbindung mit dem Sensor instabil ist. Dies hat zu einer Pause geführt, bitte verbessern Sie die Verbindung.'
    },
    TEST_SCREEN_TRAINING: {
      START: 'Start',
      STOP: 'Stopp',
      PROCEDURE_PREVIEW: 'Vorschau des Ablaufs',
      PART_TEXT: 'Abschnitt',
      CHOOSE_TEST: 'Wählen Sie einen Test',
      HEADING: 'Einen Test auswählen und durchführen',
      CONNECT_SENSOR: 'Bitte verbinden Sie den eSense EEGenius!'
    },
    DEFAULT_TEXT_OPTION: {
      CLOSE_EYES: 'Schließen Sie die Augen, bis Sie einen Ton hören',
      OPEN_EYES: 'Bitte halten Sie die Augen offen',
      ONE_COUNTDOWN: 'Bitte von 1000 im Kopf die Zahl 1 abziehen',
      SEVEN_COUNTDOWN: 'Bitte von 1000 im Kopf die Zahl 7 abziehen',
      THREE_COUNTDOWN: 'Bitte von 1000 im Kopf die Zahl 3 abziehen',
      BE_QUIET: 'Bitte bleiben Sie ruhig, mit geöffneten Augen.',
      READ_TEXT: 'Bitte lesen Sie den Text',
      LISTEN_MUSIC: 'Hören Sie die Musik'
    },
    SIMULATOR_MODE: {
      SIMULATOR_ON: 'Simulatormodus an',
      SIMULATOR_OFF: 'Simulatormodus aus'
    },
    CHART_OPTIONS: {
      CHANNEL: 'Kanal',
      CHANNEL_ONE: 'Kanal 1',
      CHANNEL_TWO: 'Kanal 2',
      Y_VALUE: 'Wert der Y-Achse (µV)',
      X_VALUE: 'Wert der X-Achse (µV)',
      AVERAGE_PRESETS: 'Durchschnitt (s) Vorgaben',
      ZOOM_STEP: 'Zoomstufe (µV)',
      AVERAGE_OSCILLOSCOPE: 'Durchschnitt (s)',
      AVERAGE_BAR: 'Durchschnitt (s) Balken',
      THRESHOLD_TYPE: 'Trainingsrichtung',
      THRESHOLD_VALUE: 'Schwellenwert',
      THRESHOLD_TYPE_INHIBIT: 'Verringern',
      THRESHOLD_TYPE_REWARD: 'Erhöhen',
      TRAINING_TYPE: 'Trainingstyp',
      HIDE_OPTIONS: 'Optionen für Benutzer ausblenden',
      SHARING_ON: 'Ja',
      SHARING_OFF: 'Nein',
      ADD_TRAINER: 'Supervisor hinzufügen (Benutzer-ID oder E-Mail)',
      ADD_TRAINER_BUTTON: 'Hinzufügen',
      ADD_TRAINER_ERROR: 'Benutzer-ID oder E-Mail ist ungültig',
      SIGNAL_DISTANCE: 'Abweichung in % :',
      SUCCESS_RATE: 'Lernerfolg in %/min :',
      SHARE_USERS_TEXT: 'Remote Training',
      AUTO_THRESHOLD: 'Automatische Schwelle',
      TRAINER_TEMPLATES: 'Wählen Sie Ihr Training',
      CONNECT_SENSOR: 'Bitte verbinden Sie Ihren eSense EEGenius!',
      ANALYSIS_TYPE: 'Art der Analyse',
      NO_DATA: 'Keine Daten',
      CHOOSE_ONE: 'Wählen Sie eine Option',
      TRAINING_DIFFICULTY: 'Schwierigkeit',
      EASY_TRAINING: 'Leicht (80-90%)',
      NORMAL_TRAINING: 'Normal (70-80%)',
      HARD_TRAINING: 'Herausfordernd (60-70%)',
      REMOTE_TRAINING: 'Remote-Training',
      TRANSMISSION_SPEED: 'Übertragungsgeschwindigkeit des Fernunterrichts'
    },
    YOUTUBE_VIDEO: {
      TRAINER_TEXT: 'Ihr Supervisor:',
      ARTEFACT_TEXT: 'Artefakt-Schwelle',
      OPEN_WINDOW: 'Im Fenster öffnen',
      VOLUME: 'Lautstärke',
      MUTE_VIDEO: 'Videoton stummschalten',
      UNMUTE_VIDEO: 'Videoton einschalten',
      DISCONNECT_TEXT: 'Trennen',
      PLAYBACK_RATE: 'Wiedergaberate',
      CHANGE_VIDEO: 'Video ändern',
      FEEDBACK_TYPE: 'Art der Rückmeldung',
      FEEDBACK_STOP: 'Start/Stopp',
      FEEDBACK_SLOW:'Normal/Langsam'
    },
    CUSTOM_TRAINING_SCREEN: {
      ENTER_USER_ID: 'Bitte Benutzer-ID oder E-Mail eingeben',
      USER_INVALID: 'Benutzer-ID oder E-Mail ist ungültig',
      CHART_OPTIONS: 'Diagramm-Optionen',
      SELECTED_USERS: 'Ausgewählte Benutzer',
      SET_OPTIONS: 'Optionen Einstellen',
      SHOW_HIDE: 'Anzeigen / Ausblenden',
      SUBMIT: 'Absenden',
      YOUTUBE_PLAYLIST: 'YouTube Wiedergabeliste URL',
      PLAYLIST_LINK_ERROR: 'Ungültiger Link zur Wiedergabeliste'
    },
    CUSTOM_TRAINING_CHART: {
      USER: 'Benutzer:',
      OPTIONS: 'Optionen',
      SENSOR_VALUE: 'Messwert:',
      STEP: 'Schritt',
      VALUES: 'Werte:',
      VIDEO_PLAY_TEXT: 'Das Video wird abgespielt:',
      ATREFAKT_SIGNAL: 'Artefakte:',
      VIDEO_PLAY: 'Ja',
      VIDEO_NOT_PLAY: 'Nein',
      DEACTIVATE_THRESHOLD: 'Automatische Schwelle deaktivieren'
    },
    SELECT_RECORDING: {
      ARCHIVE: 'Archiv',
      SELECT_USERS: 'Benutzer auswählen',
      SUPERVISORS: 'Liste der Benutzer',
      NO:'Nr.',
      CURRENT_USER: 'Mein Archiv',
      SELECT: 'Wählen Sie',
      DATE: 'Datum',
      LENGTH: 'Länge',
      TYPE: 'Name',
      BAD_SIGNAL: 'Schlechtes Signal (%)',
      NOTE: 'Notiz',
      DOWNLOAD: 'Herunterladen',
      DELETE: 'Löschen',
      TRAININGS_TAB: 'Trainings',
      TESTS_TAB: 'Tests',
      OTHERS_TAB: 'Andere',
      TO_ANALYSIS: 'Weiter zur Analyse',
      DELETE_SELECTED: 'Ausgewählte Datensätze löschen',
      CONFIRM_TEXT_FIRST: 'Sind Sie sicher, dass Sie folgendes löschen möchten:',
      CONFIRM_TEXT_SECOND: 'Datensatz (Datensätze)',
      SEARCH_TEXT: 'Suche',
      NO_RESULTS: 'Keine Ergebnisse',
      TABLE_STYLE_NORMAL: 'Normale Ansicht',
      TABLE_STYLE_EXPANDED: 'Aggregiert nach Training',
      EXPAND_MORE: 'Erweitern...',
      RECORDS_COUNT: 'Anzahl Messungen:',
      BACK_TO_TREE: 'Zurück zu Baum',
      HIDE_INCOMPLETE: 'Unvollständige Sitzungen ausblenden',
      RATING_TEXT: 'Bewertung:',
      USER_NAME: 'Name',
      USER_ID: 'ID',
      USER_EMAIL: 'E-mail',
      USER_LAST_LOGIN: 'Letzte Anmeldung',
      USER_SESSIONS: 'Anzahl der Trainings',
      USER_TOTAL_SECONDS: 'Gesamtzahl der Trainingssekunden',
      USER_GOALS: 'Ziele',
      NEW_DATA: '(neue Daten)',
      SELECT_TEXT: 'Wählen Sie',
      FILTER_ALL: 'Alle',
      FILTER_SUPERVISORS: 'Supervisoren',
      FILTER_NORMAL: 'Normale Benutzer',
      COMMENT: 'Supervisoren Kommentar',
      USER_NOTE: `Anmerkung des Benutzers`,
      SENSOR_ERRORS: 'Synchronisationsfehler:',
      SENSOR_LOST_PACKETS: 'Verlorene Pakete:',
      SENSOR_INTERRUPTIONS: 'Signalunterbrechungen:',
      LOAD_MORE: 'Mehr laden',
      INCOMPLETE: 'Unvollständig',
      PREPARING_TEXT: 'Aufzeichnung der Analyseseite vorbereiten',
      OF_TEXT: 'von'
    },
    EDIT_NOTE: {
      SAVE: 'Speichern',
      CLOSE: 'Abbrechen',
      NOTE: 'Notiz',
      INCOMPLETE: 'UNVOLLSTÄNDIG'
    },
    DELETE_RECORDING: {
      SURE_TEXT: 'Sind Sie sicher, dass Sie diesen Datensatz (Datensätze) löschen möchten?',
      CONFIRM: 'Ja, bestätigen',
      CLOSE: 'Nein, abbrechen'
    },
    ANALYSIS_SCREEN: {
      EXPORT_PDF: 'Ansicht als PDF exportieren',
      SET: 'Wähle',
      EMPTY_DATA: 'Bitte wählen Sie zuerst mindestens eine Aufzeichnung im Bereich "Aufzeichnung auswählen".',
      TREND_TEXT: 'Trend:',
      MINUTES_TEXT: 'Dauer des Tests:',
      FREQUENCY_ONE: '1. Frequenzband',
      FREQUENCY_TWO: '2. Frequenzband',
      TRAINING_TEXT: 'Ausbildung:',
      STRONGEST_TREND: 'Starker Trend',
      NORMAL_TREND: 'Normaler Trend',
      NO_TREND: 'Kein Trend',
      TREND_COLUMN: 'Säule',
      TREND_ROW: 'Reihe',
      TREND_STYLE: 'Trends anzeigen',
      TO_ARCHIVE: 'Zum Archiv',
      TOGGLE_TIMING_LINES: 'Ausblenden von Bewertungszeitbereichen',
      DATE: 'Datum:',
      LENGTH: 'Länge:',
      Notes: 'Anmerkungen:',
      TYPE: 'Typ:',
      COMPARING: 'Vergleich:'
    },
    CHARTS_NAMING: {
      AMPLITUDE: 'Amplituden-Übersicht',
      RAW_SIGNAL: 'Rohsignal',
      RATIO: 'Ratio-Übersicht',
      RATIO_ANALYSIS: 'Ratio',
      AMPLITUDE_ANALYSIS: 'Amplitude',
      TRAINING_SCREEN: 'Trainingsbildschirm',
      BOXPLOT: 'Boxplot (eine Aufzeichnung)',
      BOXPLOT_MIXED: 'Boxplot (mehrere Aufzeichnungen)',
      BOXPLOT_WITHIN: 'Boxplot innerhalb einer Aufzeichnung',
      ANALYSIS_SCREEN: 'Analysebildschirm',
      PARTS_BOX_CHART: 'Teile-Boxplot-Diagramm'
    },
    CREDITS: {
      CREDITS_TEXT: 'Credits'
    },
    DASHBOARD: {
      REWARD_NEED: 'Sie benötigen',
      TO_REACH: 'zu erreichende Punkte',
      TOTAL_TIME: 'Gesamtdauer',
      USER_LEVEL: 'Level:',
      ACTIVITY_FEED: 'Aktivitäts-Feed',
      EMAIL_NOTIFICATION: 'E-Mail-Benachrichtigung',
      SELECT_DASHBOARD: 'Andere Benutzer-Übersicht auswählen',
      YOUR_TEXT: 'Meine Übersicht',
      NEW_TRAINING: 'Neues Training vom',
      TOTAL_TRAININGS: 'Trainings insgesamt',
      DAY_TRAININGS: 'Trainings dieses Tages:',
      WEEK_TRAININGS: 'Trainings dieser Woche:',
      MONTH_TRAININGS: 'Trainings diesen Monats:',
      DAY_MINUTES: 'Heutige Trainings Minuten:',
      WEEK_MINUTES: 'Wöchentliche Trainings Minuten:',
      MONTH_MINUTES: 'Monatliche Trainings Minuten:',
      MOST_TRAINING: 'Die meisten Trainingseinheiten in einer Woche:',
      MOST_TRAINING_TRAININGS: '',
      MOST_TRAINING_RANGE: 'Bereich:',
      INTERVALS_SESSIONS: 'Kürzeste und längste Zeitspanne zwischen zwei Trainingseinheiten:',
      INTERVALS_HOURS: 'Stunden',
	  INTERVALS_NAME: 'Name:',
      INTERVALS_STREAK: 'Ihre beste Trainingsstrecke:',
      INTERVALS_MAX_DIFFERENCE: 'Größter Zeitunterschied zwischen zwei Trainingseinheiten:',
      INTERVALS_MIN_DIFFERENCE: 'Kleinster Zeitunterschied:',
      NO: 'Nr.'
    },
    TROPHY_NAMES: {
      FIRST: 'Junior Gehirn',
      SECOND: 'Trainiertes Gehirn',
      THIRD: 'Fortgeschrittenes Gehirn',
      FOURTH: 'Experten Gehirn',
      FIVE: 'Superhirn',
      SIX: 'Hyperhirn',
      SEVEN: 'Einstein'
    },
    BADGES_NAMES: {
      NONE: 'keine',
      FIRST: 'Neuling',
      SECOND: 'Anfänger',
      THIRD: 'Junior',
      FOURTH: 'Erfahren',
      FIVE: 'Fortgeschritten',
      SIX: 'Expert',
      SEVEN: 'Professionell',
      EIGHT: 'Weltklasse',
      NINE: 'Superhirn',
    },
    BILLING: {
      ERROR_TEXT: 'Bitte füllen Sie alle erforderlichen Felder aus',
      PAGE_TEXT: 'Seite',
      DATE_TEXT: 'Datum',
      TIME_TEXT: 'Zeit',
      TODAY_FILTER: 'Heute',
      WEEK_FILTER: 'Diese Woche',
      MONTH_FILTER: 'Dieser Monat',
      LAST_MONTH_FILTER: 'Letzter Monat',
      THIS_YEAR_FILTER: 'Dieses Jahr',
      SELECT_USERS_LIST: 'Liste der Benutzer',
      MY_ARCHIVE: 'Meine Statistik',
      USER_DATA: 'Benutzerdaten abrufen',
      GET_DATA: 'Daten abrufen',
      DATA_ERROR: 'Benutzer-ID oder E-Mail ist ungültig',
      TOTAL_AMOUNT: 'Gesamtzeit des gewählten Zeitraums:',
      USER_DETAILS: 'Benutzerdetails',
      COMPANY: 'Firma',
      FIRST_NAME: 'Vorname',
      SURNAME: 'Nachname',
      STREET: 'Straße',
      POST_CODE: 'Postleitzahl',
      CITY: 'Ort',
      STATE: 'Bundesland',
      COUNTRY: 'Land',
      E_MAIL: 'E-Mail',
      SAVE_CHANGES: 'Eingaben speichern',
      VALID: 'Sind alle Felder korrekt ausgefüllt?',
      SUBMIT_CHANGES: 'Speichern',
      CANCEL: 'Abbrechen',
      SUCCESS_SAVE: 'Rechnungsdaten erfolgreich aktualisiert',
      COST_PER_MIN: 'Preis pro Minute:',
      TOTAL_MINUTES: 'Gesamtdauer:',
      TIME_USAGE: 'Nutzungszeit',
      TOTAL_NUMBER: 'Trainingssitzung:',
      PAYMENT_METHOD: 'Zahlungsmethode',
      PRICE_PER_SESSION: 'Preis pro Trainingssitzung: 0,00 € inkl. 19% MwSt',
      TEST_FREE: 'Test Messungen sind immer kostenfrei.',
      TRAINING_STATUS: 'Status:',
      PAID: 'Bezahlt',
      NOT_PAID: 'Nicht gezahlt',
      GOCARDLESS_TEXT: 'Lastschrifteinzug (GoCardless)',
      PHONE: 'Telefon'
    },
    BILLING_MONTHS: {
      January: 'Januar',
      February: 'Februar',
      March: 'März',
      April: 'April',
      May: 'Mai',
      June: 'Juni',
      July: 'Juli',
      August: 'August',
      September: 'September',
      October: 'Oktober',
      November: 'November',
      December:'Dezember',
    },
    DELETE_USER: {
      HEADING: 'Sind Sie sicher, dass Sie Ihr Konto und alle Benutzerdaten löschen möchten?',
      CONFIRM: 'Bestätigen',
      CLOSE: 'Abbrechen',
      YES: 'Ja',
      NO: 'Nein',
      ANSWER: 'Antwort'
    },
    COACHMARKS_BUTTONS: {
      NEXT: 'Weiter',
      PREV: 'Vorherige',
      END: 'Ende',
      SHOW_AGAIN: 'Wieder anzeigen?'
    },
    SUPERVISOR_PROFILE_COACHMARKS : {
      MENU_ARROW: 'Das Menü schmaler oder breiter machen',
      SIDE_MENU: 'Dies ist das Seitenmenü der App. Hier finden Sie alle Bildschirme. Der Arbeitsablauf gliedert sich von oben nach unten Sie sind jetzt auf der Profilseite.',
      PROFILE_DETAILS: 'Hier können Sie Ihr Profil sehen und bearbeiten, Ihre Supervisoren und Benutzer verwalten. Sie sehen Ihre Gruppen und können einen Blick auf Ihre Abrechnungen und Nachrichten werfen. Es ist auch möglich, in einem separaten Browserfenster einen direkten oder Gruppen-Videoanruf mit einem anderen Supervisor oder regulären Benutzer zu tätigen. Sehr praktisch - halten Sie eine Webcam und ein Headset bereit.',
      SIMULATOR_MODE_MODE: 'Wenn Sie die App ohne ein angeschlossenes eSense EEGenius testen möchten, können Sie hier den Simulatormodus ein- und ausschalten. Er erzeugt zufällige Signale in allen Bildschirmen des Seitenmenüs, mit Ausnahme des "Rohsignal".',
      INFO_BUTTON: 'Klicken Sie auf diese Schaltfläche, um mehr über die von uns empfohlene Elektrodenplatzierung zu erfahren. Sehen Sie sich das Youtube-Video an, in dem detailliert erklärt wird, wie Sie die Kappe aufsetzen und die Elektroden platzieren.',
      CONNECT_BUTTON: 'Wenn Sie mit korrekt platzierten Elektroden bereit sind, schalten Sie Ihr eSense EEGenius ein und warten Sie, bis das blaue Licht dauerhaft leuchtet, damit das Gerät eine Verbindung herstellen kann. Drücken Sie dann die Taste "Verbinden".',
      LANGUAGE_SELECT: 'Wechseln Sie hier Ihre bevorzugte Sprache für die App',
      SIGN_OUT: 'Rufen Sie hier Ihr Profil auf und melden Sie sich ab.'
    },
    SUPERVISOR_AMPLITUDE_COACHMARKS: {
      SHOW_HIDE_TEXT: 'Klicken Sie auf jedes Frequenzband, um die Kurve im Oszilloskop ein- oder auszublenden.',
      CHANNEL_TEXT: 'Wählen Sie den Kanal 1 oder 2 des eSense EEGenius. Momentan ist nur Kanal 1 verfügbar.',
      Y_AXIS_TEXT: 'Einstellung des Maximalwertes der Y-Achse in µV',
      SECONDS_TEXT: 'Legen Sie die maximale Länge der X-Achse in Sekunden fest.',
      AVERAGE_TEXT: 'Stellen Sie die Durchschnittsbildung der Signale in Sekunden ein.',
      AVERAGE_PRESETS: 'Wählen Sie aus einigen durchschnittlichen Voreinstellungen in Sekunden, die Sie auf das Signal anwenden möchten.',
      ZOOM_STEP_TEXT:'Stellen Sie die Zoomstufe der Y-Achse ein, wenn Sie die Schaltflächen auf der rechten Seite des Oszilloskops verwenden.',
      CENTER_CHART_TEXT: 'Dies ist der Bildschirm Amplitudenübersicht. Sie können sich 10 vordefinierte Frequenzbänder Ihrer EEG-Daten in Echtzeit ansehen. Die Abtastung erfolgt intern mit 2000 Hz und wird im Gerät auf 25 Samples pro Sekunde für jedes Frequenzband gemittelt und über Bluetooth® übertragen und hier dargestellt.',
      ZOOM_BUTTONS: 'Vergrößern und verkleinern Sie die Y-Achse mit diesen Schaltflächen.',
      RECORD_ACTIVITY: 'Wenn Sie Ihre Aktivität von diesem Bildschirm aus im Analysebereich aufzeichnen möchten, können Sie eine voreingestellte Dauer wählen und die rote Aufnahmetaste drücken, um die Aufzeichnung zu starten. Die Aufzeichnung stoppt automatisch nach der voreingestellten Dauer oder wenn Sie manuell auf die Stopptaste drücken, um einen optimalen Vergleich bei der Analyse zu ermöglichen.'
    },
    SUPERVISOR_RAW_COACHMARKS: {
      CHANNEL_TEXT: 'Wählen Sie den Kanal 1 oder 2 des eSense EEGenius. Momentan ist nur Kanal 1 verfügbar.',
      Y_AXIS_TEXT: 'Einstellung des Maximalwertes der Y-Achse in µV',
      SECONDS_TEXT: 'Legen Sie die maximale Länge der X-Achse in Sekunden fest.',
      ZOOM_STEP_TEXT:'Stellen Sie die Zoomstufe der Y-Achse ein, wenn Sie die Schaltflächen auf der rechten Seite des Oszilloskops verwenden.',
      ZOOM_BUTTONS: 'Vergrößern und verkleinern Sie die Y-Achse mit diesen Schaltflächen.',
      CENTER_CHART_TEXT: 'Dies ist der Bildschirm Rohsignal. Sie können sich Ihr Rohsignal der EEG-Daten in Echtzeit ansehen. Die Abtastung erfolgt mit 250 Hz und wird über Bluetooth® übertragen, um hier angezeigt zu werden.'
    },
    SUPERVISOR_RATIO_COACHMARKS: {
      SHOW_HIDE_TEXT: 'Klicken Sie auf jedes Frequenzband, um die Kurve im Oszilloskop ein- oder auszublenden.',
      CHANNEL_TEXT: 'Wählen Sie den Kanal 1 oder 2 des eSense EEGenius. Momentan ist nur Kanal 1 verfügbar.',
      Y_AXIS_TEXT: 'Einstellung des Maximalwertes der Y-Achse in µV',
      SECONDS_TEXT: 'Legen Sie die maximale Länge der X-Achse in Sekunden fest.',
      AVERAGE_TEXT: 'Stellen Sie die Durchschnittsbildung der Signale in Sekunden ein.',
      AVERAGE_PRESETS: 'Wählen Sie aus einigen durchschnittlichen Voreinstellungen in Sekunden, die Sie auf das Signal anwenden möchten.',
      ZOOM_STEP_TEXT:'Stellen Sie die Zoomstufe der Y-Achse ein, wenn Sie die Schaltflächen auf der rechten Seite des Oszilloskops verwenden.',
      CENTER_CHART_TEXT: 'Dies ist der Bildschirm Ratio Overview. Sie können sich 3 frei wählbare Verhältnisse der Frequenzbänder Ihrer EEG-Daten in Echtzeit ansehen. Die Abtastung erfolgt intern mit 2000 Hz und wird im Gerät auf 25 Samples pro Sekunde für jedes Frequenzband gemittelt und über Bluetooth® übertragen und hier dargestellt. ',
      ZOOM_BUTTONS: 'Vergrößern und verkleinern Sie die Y-Achse mit diesen Schaltflächen.',
      GLOBAL_AVERAGES_TEXT: 'Zusätzlich zu den drei ausgewählten Ratios finden Sie hier einige häufig verwendete Ratios in Zahlen berechnet (basierend auf einem 60s Durchschnitt).'
    },
    SUPERVISOR_TEST_CONFIG_COACHMARKS: {
      PARTS_TEXT: 'Wählen Sie bis zu 10 Abschnitte innerhalb einer einzigen Testvorlage aus.',
      LENGTH_TEXT: 'Legen Sie die Länge der einzelnen Abschnitte individuell fest.',
      CUSTOM_TEXTS: 'Benutzerdefinierte Texte hinzufügen',
      EACH_PART_TEXT: 'Wählen Sie für jeden Teil einen Text aus.',
      SOUND_TEXT: 'Wählen Sie eine optionale Tondatei oder aktivieren Sie das Kontrollkästchen, um am Ende eines Abschnitts einen Ton abzuspielen.',
      IMAGE_TEXT: 'Fügen Sie ein beliebiges Bild hinzu, z. B. mit Anweisungen oder einfach nur als entspannenden Hintergrund',
      TONE_TEXT: 'Testen Sie den Ton, der am Ende eines Abschnitts mit aktivem Kontrollkästchen gespielt wird.',
      CREATE_TEXT: 'Weisen Sie Ihrer erstellten Testkonfiguration einen Benutzer zu und speichern Sie sie unter einem Namen. Sie können den Test dann auch starten, um ihn selbst auszuprobieren.',
      PAGE_TEXT: 'Dies ist der Bildschirm "Testkonfiguration". Als Supervisor können Sie eigene Testsituationen für Ihre Benutzer definieren. Sie können bis zu 10 Abschnitte mit benutzerdefinierter Länge definieren, die mit einem Text, einem Ton und/oder einem Bild versehen sind. Sie können die Tests speichern und bestimmten Benutzern zuweisen, die sie dann automatisch ausführen können. Im Bereich Analyse ist eine detaillierte Überprüfung der Tests möglich.'
    },
    SUPERVISOR_TEST_SCREEN_COACHMARKS: {
      PAGE_TEXT: 'Dies ist der Bildschirm "Test". Hier können Sie einen Test auswählen und ausführen, den Sie im Bildschirm "Testkonfiguration" erstellt haben oder der Ihnen von einem anderen Supervisor zugewiesen wurde.'
    },
    SUPERVISOR_TRAINING_CONFIG_COACHMARKS: {
      PARTS_TEXT: 'Wählen Sie bis zu 3 Abschnitte innerhalb eines einzigen Trainings aus.',
      TOPIC_TEXT: 'Auswahl eines Untertiteltextes für jeden Teil, der dem Benutzer später beim Training angezeigt wird.',
      LENGTH_TEXT: 'Wählen Sie die Länge der einzelnen Abschnitte.',
      TRAINING_TYPE_TEXT: 'Sie können entweder Ratio- oder Amplitudentraining wählen.',
      BAND_TEXT: 'Wählen Sie das Frequenzband/die Frequenzbänder, die Sie trainieren möchten.',
      THRESHOLD_TYPE_TEXT: 'Belohnung (Erhöhung) oder Hemmung (Verringerung) der Amplitude oder des Ratios.',
      YOUTUBE_TEXT: 'Wählen Sie eine YouTube-Playlist mit Feedback-Videos für den Benutzer oder laden Sie ein eigenes Video hoch.',
      NOTES_TEXT: 'Fügen Sie eine Notiz hinzu, die dem Benutzer für jeden Abschnitt angezeigt wird, während das Training läuft.',
      CREATE_TEXT: 'Weisen Sie Ihrer erstellten Trainingskonfiguration einen Benutzer zu und speichern Sie diese unter einem Namen. Sie können die gleiche Trainingsvorlage immer mehreren Benutzern zuordnen oder auch wieder von einem Benutzer entfernen.',
      PAGE_TEXT: 'Dies ist die Trainings-Konfiguration. Als Supervisor können Sie individuelle Trainingssituationen für Ihre Benutzer definieren. Sie können bis zu 5 Abschnitte von benutzerdefinierter Länge mit einem Thema und Ihren bevorzugten Trainingsparametern definieren. Fügen Sie Ihre individuelle YouTube-Wiedergabeliste für benutzerdefinierte Feedback-Videos hinzu, laden Sie ein eigenes Video hoch oder wählen Sie die Standardvideos. Fügen Sie eine Notiz hinzu, um dem Benutzer den Zweck dieses Trainings zu verdeutlichen. Sie können Trainingsvorlagen speichern und bestimmten Benutzern zuweisen, die sie dann so oft wie gewünscht durchführen können. Im Bereich Analyse ist eine detaillierte Überprüfung der Trainingsmessungen möglich.'
    },
    SUPERVISOR_TRAINING_PREPARE_COACHMARKS: {
      MAIN_TEXT: 'Dies ist der Bildschirm "Voreingestellte Schulungen". Hier können Sie ein Training auswählen, das Sie im Bildschirm Trainingskonfiguration erstellt haben oder das Ihnen von einem anderen Vorgesetzten zugewiesen wurde. Richten Sie Ihr Gerät wie gewohnt ein und schließen Sie es an. Wenn die Signalqualität gut ist, d. h. gute Impedanz und geringe (Muskel-)Artefakte, können Sie einen Test auswählen. Andernfalls überprüfen und verbessern Sie bitte die Verbindung.'
    },
    SUPERVISOR_TRAINING_COACHMARKS: {
      HIDE_ELEMENT: 'Verwenden Sie die Augen-Buttons, um bestimmte Elemente des Bildschirms ein- oder auszublenden.',
      HIDE_OPTIONS: 'Optionen für benutzerdefinierte Trainingsanpassungen für Supervisoren ein-/ausblenden',
      YOUTUBE_TEXT: 'Das YouTube-Video startet/stoppt automatisch, wenn der Schwellenwert erfüllt ist oder nicht.',
      PAGE_TEXT: 'Dies ist der Trainingsbildschirm. Hier können Sie ein Neurofeedback-Training in Echtzeit durchführen. Als Supervisor können Sie einfach ein benutzerdefiniertes Training starten und aufzeichnen, das Sie in beliebiger Weise spezifizieren können. Als normaler Benutzer können Sie die Trainings durchführen, die Ihnen zugewiesen wurden, oder einige Standardprotokolle auswählen. In der Analyse-Sektion ist eine detaillierte Überprüfung der Trainings möglich.',
      SIGNAL_TEXT: 'Hier wird das Signal (Amplitude oder Ratio) als Linie in Echtzeit gezeichnet.',
      SIGNAL_DISTANCE: 'Beobachten Sie den Abstand Ihres aktuellen Signals zum eingestellten Schwellenwert in % und die Erfolgsquote der positiven Rückmeldung in % der letzten Minute. Der Abstand sollte etwa 20-30 % (plus oder minus, je nach Trainingszustand) und die Erfolgsquote zwischen 60-90 % liegen, um optimale Lernergebnisse zu erzielen.',
      ARTEFACT_TEXT: 'Muskelartefakte im EEG-Signal werden überwacht und hier angezeigt. Sie können einen Schwellenwert (2, 4 oder 6 µV) wählen, ab dem die Aufzeichnung dynamisch pausiert, wenn die Artefakte zu hoch sind und über diesem Grenzwert liegen.',
      RECORD_ACTIONS: 'Wenn Sie Ihre Aktivität von diesem Bildschirm aus im Analysebereich aufzeichnen möchten, können Sie eine voreingestellte Dauer wählen und die rote Aufnahmetaste drücken, um die Aufzeichnung zu starten. Die Aufzeichnung stoppt automatisch nach der voreingestellten Dauer oder wenn Sie manuell auf die Stopptaste drücken, um einen optimalen Vergleich bei der Analyse zu ermöglichen.'
    },
    SUPERVISOR_RECORDINGS_COACHMARKS: {
      PARTS_TEXT: 'Sie müssen mindestens eine Aufzeichnung für die weitere Analyse auf dem "Analysebildschirm" auswählen',
      ARCHIVE_TEXT: 'Dies ist die Liste aller Benutzer, die Sie als ihren Vorgesetzten zugewiesen haben. Sie können diese Liste sortieren, indem Sie auf die verschiedenen Überschriften der Spalten klicken. Sie können nach einem bestimmten Benutzer im Suchfeld nach Name, ID oder E-Mail suchen.',
      SELECT_USER: 'Wählen Sie einen Benutzer aus, um das Archiv Ihrer Kunden zu analysieren. Wenn kein Benutzer ausgewählt ist, sehen Sie unten Ihre eigenen Aufzeichnungen.',
      SELECT_USER_GOALS: 'Sehen Sie die Ziele, die Ihre Benutzer in der Umfrage in ihrem Profil für ihr Neurofeedback-Training definiert haben.',
      NOTES_TEXT: 'Fügen Sie für jede Ihrer eigenen Aufnahmen eine Notiz hinzu oder bearbeiten Sie diese.',
      DELETE_TEXT: 'Löschen Sie bei Bedarf Ihre eigenen Aufnahmen.',
      DOWNLOAD_TEXT: 'Laden Sie jede Aufzeichnung als CSV-Datei herunter, einschließlich aller Amplituden- und Ratio-Verläufe.',
      TOGGLE_TEXT: 'Toogeln Sie zwischen Trainings, Tests oder anderen Aufzeichnungen in den Fenstern Amplitude, Ratio oder Rohsignaldiagramm.',
      PAGE_TEXT: 'Dies ist die Liste aller Aufnahmen, die für Ihren Benutzer oder andere Benutzer, die Sie als Supervisor gewählt haben, gemacht wurden. Sie können diese Liste sortieren, indem Sie auf die verschiedenen Überschriften der Spalten klicken. Sie können zu jeder Aufzeichnung Notizen hinzufügen/bearbeiten, sie als CSV-Dateien zur weiteren Analyse in Excel o.ä. herunterladen oder sie löschen.'
    },
    SUPERVISOR_ANALYSIS_COAHMARKS: {
      Y_AXIS_TEXT: 'Einstellung des Maximalwertes der Y-Achse in µV',
      SECONDS_TEXT: 'Legen Sie die maximale Länge der X-Achse in Sekunden fest.',
      ANALYSIS_TYPE: 'Wählen Sie die Art der Analyse',
      AVERAGE_TEXT: 'Stellen Sie die Durchschnittsbildung der Signale in Sekunden ein.',
      AVERAGE_PRESETS: 'Wählen Sie aus einigen durchschnittlichen Voreinstellungen in Sekunden, die Sie auf das Signal anwenden möchten.',
      ZOOM_STEP_TEXT:'Stellen Sie die Zoomstufe der Y-Achse ein, wenn Sie die Schaltflächen auf der rechten Seite des Oszilloskops verwenden.',
      PDF_INFO: 'Einige Informationen über die sichtbare(n) Aufnahme(n)',
      ZOOM_BUTTONS: 'Vergrößern und verkleinern Sie die Y-Achse mit diesen Schaltflächen.',
      EXPORT_PDF_TEXT: 'Exportieren Sie die aktuelle Ansicht als PDF-Datei.',
      CENTER_CHART_TEXT: 'Dies ist der Bildschirm "Analyse". Hier sehen Sie die Analyse der Aufzeichnung(en), die Sie zuvor im Bildschirm "Aufzeichnung(en) auswählen" ausgewählt haben. Sie können zwischen verschiedenen Arten von Analysen wählen (Liniendiagramm, Boxplot verschiedener Typen) und die Diagramme als PDF-Dateien zum Herunterladen exportieren.'
    },
    SUPERVISOR_DASHBOARD_COACHMARKS: {
      PROFILE_TEXT: 'Je mehr Sie trainieren, desto mehr Punkte sammelst Sie. Ein Punkt pro Sekunde des Trainings!',
      EMAIL_TEXT: 'Als Supervisor können Sie auch die Dashboards Ihrer Benutzer und deren Fortschritte beobachten.',
      ACTIVITY_TEXT: 'Beobachten Sie Ihre Aktivitäten in diesem Feed und schalten Sie E-Mail-Benachrichtigungen darüber ein oder aus.',
      TOTAL_TRAININGS_TEXT: 'Je mehr Sie trainieren, desto mehr Trophäen können Sie verdienen. Erreichen Sie die angezeigte Anzahl von Trainings, um sie zu erhalten!',
      STATISTIC_TEXT: 'Hier sehen Sie die Statistiken für den aktuellen Tag, die Woche und den Monat sowie die beste Woche des Monats.',
      CALENDAR_TEXT: 'Jedes Training und jeder Test, der absolviert wurde, wird in diesen Kalender eingetragen, und Sie erhalten dafür eine Medaille.',
      PROGRESS_TEXT: 'Verfolgen Sie Ihren Trainings- und Testfortschritt in diesem Dashboard. Sie können verschiedene Belohnungen verdienen und bestimmte Stufen erreichen. Sie sehen Ihre Aktivitäten in einer Historie und Ihre Neurofeedback-Trainings- und Testaktivitäten in Zahlen und in einer Kalenderansicht. Trainieren Sie weiterhin regelmäßig, um die besten Ergebnisse zu erzielen!'
    },
    SUPERVISOR_REMOTE_COACHMARKS: {
      CHOOSE_USER: 'Wählen Sie den/die Benutzer, mit dem/denen Sie trainieren möchten, und klicken Sie auf "Senden", um die Diagramme unten hinzuzufügen.',
      PAGE_TEXT: 'Dies ist der Bildschirm für das Ferntraining. Ein leistungsstarkes Echtzeit-Neurofeedback ist zwischen einem Supervisor und bis zu 4 Nutzern gleichzeitig möglich. Sie können ein individuelles Trainingsprotokoll für jeden Benutzer definieren und die Trainingsparameter und den Fortschritt in Echtzeit sehen.',
      OPTIONS: 'Mit all diesen Optionen können Sie die Trainingsparameter jedes einzelnen oder aller Benutzer definieren und in Echtzeit ändern. Dadurch ändert sich deren Trainingsbildschirm sofort. Sie haben die volle Fernsteuerung, wenn Ihr Kunde Sie als Supervisor auf seinem Trainingsbildschirm hinzugefügt und aktiviert hat.',
      THRESHOLD: 'Klicken Sie auf die Schaltfläche "Autoschwelle", um schnell eine passende Schwelle einzustellen.',
      PARAMETERS: 'Sehen Sie sich die Trainingsparameter an, die dieser Benutzer hat.',
      HIDE_ELEMENT: 'Wechseln Sie zwischen dieser Trainingsansicht, der Amplituden- oder Ratioübersicht und blenden Sie Elemente auf dem Trainingsbildschirm Ihrer Benutzer mit den Augen-Buttons ein und aus.',
      USER_CREDS: 'Sehen Sie sich die Trainingsparameter an, die dieser Benutzer hier hat.',
      CHART_TEXT:'Hier können Sie das Trainingssignal, den Schwellenwert, das Balkendiagramm, die Artefakte und die Erfolgsparameter Ihres Nutzers in Echtzeit verfolgen.'
    },
    DEFAULT_TRAININGS: {
      RELAX: 'Entspannungs Training',
      FOCUS: 'Fokus- & Konzentrationstraining',
      SPEED_UP: 'Beschleunigen',
      SLOW_DOWN: 'Verlangsamen'
    },
    DEFAULT_TESTS: {
      FOUR_MINUTES: 'Standard 4-Minuten-Test'
    },
    DEFAULT_TRAINING_RELAX: {
      FIRST_SLIDE: 'Dies ist ein Basistraining von 10 Minuten Alpha-Training mit dem Ziel, die Alpha-Amplitude zu erhöhen.',
    },
    DEFAULT_TRAINING_FOCUS: {
      FIRST_SLIDE: 'Dies ist ein grundlegendes Training von SMR, LO-BETA Amplitude, um Ihren Fokus zu erhöhen.',
      SECOND_SLIDE: 'Dies ist ein grundlegendes Training der BETA 1-Amplitude, um Ihre Konzentration zu steigern.',
    },
    DEFAULT_TRAINING_SPEED: {
      FIRST_SLIDE: 'Beschleunigen Sie Ihr Gehirn mit diesem 10-minütigen Training zur Verringerung des Verhältnisses von THETA / BETA 1, das THETA weniger dominant und BETA 1 prominenter macht.'
    },
    DEFAULT_TRAININGS_SLOW: {
      FIRST_SLIDE: 'Verlangsamen Sie Ihr Gehirn mit mehr ALPHA als SMR, LO-BETA durch Erhöhung dieses Verhältnisses.'
    },
    CUSTOM_VIDEO: {
      TYPE_HEADING: 'Benutzerdefiniert',
      UPLOAD_VIDEO: 'Bitte laden Sie Ihr Video hoch',
      VIDEO_TYPE: 'Video-Typ',
      VIDEO_PRIVATE: 'Privat',
      VIDEO_PUBLIC: 'Öffentlich',
      DROP_TEXT: 'Bitte stellen Sie Ihr Video hier ein oder klicken Sie in den Bereich',
      YOUR_VIDEOS: 'Ihre Videos:',
      PUBLIC_VIDEOS: 'Öffentliche Videos:',
      UPLOADED_TEXT: 'Hochgeladen:',
      VIDEO_UPLOADING: 'Video wird hochgeladen...',
      SELECT_FROM_EXITING: 'Oder wählen Sie aus spannenden Videos:',
      UPLOAD_VIDEO_HINT: 'Unterstützte Dateiformate: .mp4, .webm, .ogg Maximale Dateigröße: 250mb',
      FILE_LIMIT_WARNING: 'Die Größe der Videodatei ist größer als 150 MB',
      STORAGE_USED: 'Verwendeter Speicherplatz',
      MAX_STORAGE_USED: 'Sie haben den maximal zulässigen Cloud-Speicherplatz genutzt',
      LIMIT_HINT: '250 MB Dateigrößenbegrenzung und 5 GB Gesamtspeicher'
    },
    DELETE_CUSTOM_VIDEO: {
      CONFIRM_TEXT: 'Sind Sie sicher, dass Sie das Video löschen möchten?'
    },
    VIDEO_HINT: {
      HEADING: 'Video von letzter Position fortsetzen?',
      CONTINUE: 'Weiter von der letzten Position',
      RESTART: 'Neustart von Anfang an'
    },
    BAD_ARTEFAKT: {
      HEADING: 'Die Aufzeichnung wurde wegen schlechten Elektrodenkontakts oder zu hoher Signalartefakte unterbrochen. Die Elektrodenimpedanz muss unter 20 kOhm bleiben (siehe Wert oben) und die Artefakte müssen unter 6µV bleiben (siehe Ampel). Bitte verbessern Sie die Elektrodenimpedanz oder versuchen Sie, Bewegungen und Berührungen zwischen Elektrode und Kabel zu vermeiden. Ist alles im grünen Bereich, können Sie die Messung wiederaufnehmen.',
      ARTEFAKT_FIRST_PART: 'Signal-Artefakte = < 6µV',
      ARTEFAKT_SECOND_PART: 'Ihr aktuelles Signal Artefakte =',
      IMPEDANCE_FIRST_PART: 'Gute Impedanz = 2-20 kOhm',
      IMPEDANCE_SECOND_PART: 'Ihre aktuelle Impedanz ='
    },
    SURVEY: {
      MAIN_HEADING: 'Meine Ziele beim Neurofeedback Training sind?',
      HINT_HEADING: 'Bitte wählen Sie aus den folgenden Themen, damit wir verstehen können, welche Ziele Sie mittels Neurofeedback Training mit dem eSense EEGenius erreichen möchten.',
      SUB_HEADLINE: 'Meine Ziele/Themen sind (bitte wählen Sie aus einer oder mehreren Antworten):',
      MAIN_TOPIC: 'Bitte wählen Sie ein Hauptthema',
      SURVEY_AWARENESS: 'Aufmerksamkeit',
      AWARENESS_FIRST: 'Ich möchte mich besser konzentrieren.',
      AWARENESS_SECOND: 'Ich möchte meine Aufmerksamkeitsspanne verbessern.',
      AWARENESS_THIRD: 'Ich möchte mich besser fokussieren können.',
      AWARENESS_FOUR: 'Ich möchte mein Denken beschleunigen.',
      SURVEY_RELAX: 'Entspannung',
      RELAX_FIRST: 'Lass mich meinen Geist beruhigen',
      RELAX_SECOND: 'Helfen Sie mir, mein hyperaktives Gehirn zu verlangsamen',
      RELAX_THIRD: 'Ich möchte einen meditativen Zustand trainieren',
      SURVEY_IMPROVE: 'Verbesserungen',
      IMPROVE_FIRST: 'Ich möchte meine Schlafqualität verbessern.',
      IMPROVE_SECOND: 'Ich möchte mein Gedächtnis verbessern.',
      SURVEY_PREVENTION: 'Vorbeugung',
      PREVENTION_FIRST: 'Ich möchte ein spezielles Trainingsprotokoll für ältere Menschen anwenden, um aufmerksamkeitsbedingten Störungen oder kognitivem Abbau vorzubeugen',
      PREVENTION_SECOND: 'Ich verwende Neurofeedback gerne zur Förderung der Resilienz und zur Vorbeugung der negativen Auswirkungen von chronischem Stress."',
      NEXT_STEP: 'Nächster Schritt',
      FINISH: 'Speichern',
      BACK: 'Zurück',
      SURVEY_SAVED: 'Aktualisierter Fragebogen',
      SURVEY_EMPTY: 'Bitte füllen Sie den Fragebogen aus:',
      CHANGE_GOALS: 'Sie können Ihre Ziele jederzeit ändern und diese Fragen erneut beantworten.',
      SURVEY_HINT_TITLE: 'Software Update vom 21.07.2023',
      SURVEY_HINT_TEXT: 'Wir haben die eSense EEGenius Web App erneut aktualisiert und weitere Verbesserungen umgesetzt. Aufgrund des Updates müssen Sie den Fragebogen im Profil bitte erneut ausfüllen. Wir entschuldigen uns für die Umstände.'
    },
    USER_GOALS: {
      HEADING: 'Ausgewählte Nutzerziele',
      USER_TEXT: 'User',
      MAIN_GOAL: 'Hauptziel'
    },
    TRAINING_PREPARE: {
      HEADING: 'Haben Sie Ihr eSense EEGenius eingerichtet und angeschlossen?',
      SIGNAL_QUALITY: 'Wie ist Ihre Signalqualität?',
      SELECT_TRAINING: 'Bitte wählen Sie Ihr Trainingsprotokoll:',
      SELECT_TEST: 'Bitte wählen Sie Ihren Test:',
      CONCENTRATION: 'Bessere Konzentration',
      IMPEDANCE: 'Impedanz:',
      MUSCLE: '(Muskel)Artefakte:',
      CALM: 'Meinen Geist beruhigen',
      IMPROVE: 'Mein Gedächtnis zu verbessern',
      WARNING: 'Bitte schließen Sie Ihren eSense an und/oder verbessern Sie die Signalqualität, bevor Sie Ihr Training auswählen können.',
      FREE_SESSION: 'Die Testsitzungen sind immer kostenlos.',
      TRAINING_MESSAGE: 'Preis pro Trainingssitzung: 0,00 €',
      TODO: 'Preis pro Trainingssitzung: 0,00 € inkl. 19% MwSt.',
      TO_TRAINING: 'Zum Trainingsbildschirm gehen',
      CUSTOM_TRAINING: 'Ihre individuell erstellten Trainingsprotokolle von Ihrem Supervisor:',
      CUSTOM_TEST: 'Ihre individuell erstellten Testmessungen von Ihrem Supervisor:',
      SESSIONS_COUNT: 'Monatliche Trainingssitzungen',
      LEFT_PER_MONTH: 'Verbleibende Trainingszeit pro Monat:',
      NEXT_SESSION_AVAILABLE: 'Die nächste Schulung findet statt in:',
      LIMIT_REACHED: 'Sie haben Ihr Limit von 900 Minuten pro Monat erreicht'
    },
    ELECTRODE_CONTACT_DIALOG: {
      TEXT_IMPEDANCE: 'Um die Impedanz Ihrer Elektroden zu verbessern, überprüfen Sie bitte nochmals den Elektrodenkontakt. Sind die Elektroden gut platziert? Sitzen sie fest genug mit gutem Kontakt? Sind die kleinen Filzpads vollständig mit NaCl getränkt, um eine vollständige Verbindung zwischen Haut und Elektrode herzustellen? Bitte platzieren Sie die Elektroden erneut, fügen Sie bei Bedarf etwas mehr NaCl hinzu und warten Sie dann einige Sekunden, bis neue Impedanzwerte berechnet werden, wenn die Elektroden wieder an ihrem Platz sind.',
      TEXT_ARTEFAKT: 'Bitte bewegen Sie Ihren Kopf nicht und spannen Sie Ihre Muskeln im Gesicht (Augenbrauen, Ohren, Kiefer) nicht an. Bleiben Sie ruhig und Ihre (Muskel-)Artefakte werden leicht auf einen kleinen Wert unter 6µV fallen und dort bleiben. Es ist wichtig, dass Sie einen niedrigen Wert während des gesamten Trainings beibehalten. Das EEG-Signal ist ein winziges und empfindliches Signal, das leicht durch Bewegungen gestört werden kann. Bitte entspannen Sie sich und bleiben Sie während der gesamten Messung so gut wie möglich ruhig sitzen.'
    },
    INVOICE: {
      DOWNLOAD: 'Rechnung herunterladen'
    },
    USER_FILLING: {
      HEADING: 'Willkommen zur eSense EEGenius Web App',
      SUBHEADING: 'Bitte folgen Sie diesen einfachen Schritten:',
      FIRST_STEP: 'Füllen Sie den Fragebogen auf dieser Profilseite aus, damit wir Ihre Ziele beim Neurofeedback-Training verstehen.',
      SECOND_STEP: 'Vervollständigen Sie Ihre persönlichen Daten auf der Rechnungsseite des Profils.',
      THIRD_STEP: 'Platzieren Sie die Elektroden und verbinden Sie den eSense EEGenius. Die Buttons oben rechts helfen dabei.',
      DONT_SHOW_AGAIN: 'Verstanden, bitte nicht erneut anzeigen.',
      NEXT_STEP: 'Nächster Schritt',
      PREVIOUS_STEP: 'Vorheriger Schritt',
      CLOSE: 'Schließen'
    },
    APPROVAL_DIALOG: {
      LIST: 'Liste von Benutzern, die auf Bestätigung warten',
      DATE: 'Datum der Anfrage',
      STATUS: 'Status',
      ACTION: 'Aktion',
      PENDING: 'Noch nicht bestädigt',
      USER_ACCEPTED: 'Benutzer akzeptieren',
      USER_DECLINED: 'Benutzer ablehnen',
      CLOSE: 'Schließen'
    },
    ACTIVITY_FEED: {
      REMOVED_SUPERVISOR: 'Sie haben einen Supervisor entfernt!',
      ADDED_SUPERVISOR: 'Sie haben einen Supervisor hinzugefügt!',
      ACCEPTED_USER: 'Sie haben einen Benutzer akzeptiert!',
      DECLINED_USER: 'Sie haben einen Benutzer abgelehnt!'
    },
    TRAINING_SCREEN: {
      THRESHOLD_CHANGED: 'Schwellenwert funktioniert jetzt im manuellen Modus',
      THRESHOLD_AUTO: 'Der Schwellenwertalgorithmus ist optimiert und arbeitet im automatischen Modus',
      SHORT_SESSION: 'Diese Sitzung wurde vor dem regulären Ende abgebrochen. Sie ist kürzer als 3 Minuten und dies macht eine sinnvolle Analyse unmöglich. Sie wird nicht gespeichert. Bitte beginnen Sie erneut und führen Sie ein Training vollständig durch.',
      SAVING_SESSION: 'Ihre Messung wird gespeichert...',
      SENSOR_DISCONNECT: 'Ihre Bluetooth Verbindung mit dem eSense EEGenius ging komplett verloren und wurde getrennt. Wir versuchen eine bestehende Messung zu stoppen und zu speichern, sollten Sie gerade einen Test oder ein Training durchführen. Bitte schalten Sie andere Bluetooth Geräte neben dem eSense EEGenius aus. Prüfen Sie Ihre Bluetooth Treiber und den Batteriestatus des eSense EEGenius. Verbinden Sie ihn dann erneut und starten erneut.'
    },
    SUPERVISORS_TREE: {
      SEARCH: 'Suche',
      SORTING: 'Sortierung',
      BY_NAME: 'Nach Name sortieren',
      BY_LAST_LOGIN: 'Sortieren nach Datum der letzten Anmeldung',
      BY_NUMBER: 'Sortieren nach Anzahl der Benutzer',
      LAST_LOGIN: 'Letzte Anmeldung:',
      SUPERVISOR_ARCHIVE: `Archiv des Supervisors`,
      TOTAL_TRAININGS: 'Trainings insgesamt:',
      USER_ARCHIVE: 'Archiv des Benutzers',
      ALLOWED_AS_SUPERVISOR: 'Benutzer dieses Supervisors:',
      HIDDEN_SORT: 'Versteckte Supervisor ausblenden'
    },
    SUPERVISOR_ACTIVITY: {
      SEND_EMAIL: 'E-Mail zur Erinnerung senden',
      MONTHS_AGO: 'vor Monaten',
      EMAIL_SENT: 'E-Mail-Erinnerung erfolgreich gesendet',
      REMIND_SENT: 'Erinnerung gesendet:',
      EEG_USER: '(EEGenius user)',
      CHANGE_ROLE: 'Rolle ändern',
      CONFIRM_TEXT: 'Sind Sie sicher, dass Sie die Rolle für diesen Supervisor auf "Normal" ändern möchten?',
      DELETE_DATA: 'Alle Daten löschen',
      DELETE_DATA_TEXT: 'Sind Sie sicher, dass Sie alle Benutzerdaten für diesen Benutzer löschen möchten?',
      DATA_DELETED: 'Alle Benutzerdaten gelöscht!'
    },
    PHONE_WARNING: {
      TEXT:  'Wir haben festgestellt, dass Sie versuchen, den eSense EEGenius auf einem Smartphone zu verwenden. Die Bildschirmgröße ist zu klein für eine korrekte Bedienung der eSense EEGenius App. Bitte verwenden Sie ein Apple iPad®, iPad Air®, iPad mini® oder iPad Pro® mit iPadOS® mit der eSense EEGenius iOS App oder ein leistungsfähiges Android Tablet mit dem Google Chrome Browser. Vielen Dank!'
    },
    SAFARI_WARNING : {
      TEXT: 'Wir haben festgestellt, dass Sie ein Supervisor sind und versuchen, die eSense EEGenius App auf einem mobilen Gerät zu verwenden. Die Bildschirmgröße ist zu klein, um die Supervisor-Funktionen der eSense EEGenius App richtig nutzen zu können. Nur normale Benutzer können ein mobiles Gerät (iPad, Android Tablet) zur Durchführung eines Trainings verwenden. Als Supervisor verwenden Sie bitte die eSense EEGenius App auf einem Windows-, Mac- oder Linux-Computer mit Google Chrome Browser. Vielen Dank!'
    },
    REMOVE_SUPERVISOR: {
      TEXT: 'Sind Sie sicher, dass Sie diesen Supervisor entfernen möchten?',
      FROM_LIST: 'von der Liste?',
      CONFIRM: 'Ja, bestätigen',
      ABORT: 'Abbrechen'
    },
    CHAT_WIDGET: {
      ACTIVE: 'Aktiv',
      AWAY: 'Offline',
      SEND: 'Senden',
      MESSAGE: 'Nachricht',
      SEARCH_TEXT: 'Nachrichten finden',
      SEARCH_MESSAGES: 'Nachrichten:',
      SUPERVISOR_COMMENT: 'Es gibt einen neuen Kommentar:',
      SUGGESTIONS: 'Vorschläge',
      SUGGESTIONS_TOOLTIP: 'Hier finden Sie Schnellvorschläge für den Chat, abhängig von der gewählten Sprache der Anwendung.',
      SEARCH_USER: 'Suche',
      ADD_TEXT_TO_ANSWER: 'Text zur Schnellantwort hinzufügen',
      QUICK_ANSWERS: 'Schnelle Antworten',
      SAVE_ANSWER: 'Antwort speichern',
      TITLE: 'Titel',
      CANCEL: 'Abbrechen',
      SAVE: 'Speichern',
      SUPPORT_CHAT: 'Chat Hilfe'
    },
    DELETE_USER_DIALOG: {
      CONFIRM_TEXT: 'Sind Sie sicher, dass Sie diesen Benutzer aus Ihrer Liste löschen möchten?'
    },
    BLUETOOTH_DEVICE_HINT: {
      TEXT: 'Achtung: Es sollte kein weiteres Bluetooth Gerät außer des eSense EEGenius mit Ihrem PC/Laptop/iPad verbunden sein. Trennen Sie eventuelle Verbindungen zu anderen Bluetooth Geräten, wenn Sie den eSense EEGenius verwenden.'
    },
    SENSOR_STATS: {
      TOTAL_PACKETS: 'Pakete insgesamt:',
      PACKETS_DROPPED: 'Verlorene Pakete:',
      SYNC_ERROR: 'Synchronisationsfehler:',
      TOTAL_ERROR: 'Fehler insgesamt:'
    },
    CHAT_TEMPLATES: {
      MAIN_HEADING: 'Vorlage erstellen',
      MESSAGE_TITLE: 'Titel der Nachricht',
      MESSAGE_TEXT: 'Nachricht Frage',
      MESSAGE_ANSWER: 'Antwort auf die Nachricht',
      SELECT_LANGUAGE: 'Sprache auswählen',
      ENGLISH_TEXT: 'Englisch',
      GERMAN_TEXT: 'Deutsch',
      SUBMIT_TEXT: 'Einreichen',
      SUCCESS_ADDED: 'Nachrichtenvorlage hinzugefügt',
      SUCCESS_DELETED: 'Nachrichtenvorlage gelöscht',
      ACTION: 'Aktion',
      LANGUAGE:'Sprache',
      TEMPLATES_HEADER : 'Chat-Vorlagen',
      HEADER_CONFIRM_DELETE: 'Bestätigen Löschen',
      MODAL_QUESTION : 'Sind Sie sicher, dass Sie diese Vorlage löschen möchten',
      DELETE: 'Löschen ',
      CANCEL: 'Abbrechen',
      ADD_ROW: 'Zeile hinzufügen',
      CHILD_QUESTION: 'Unterfrage',
      CHILD_ANSWER: 'Teilantwort',
      CHAT_UPDATE: 'Aktualisieren',
      SUCCESS_UPDATED: 'Erfolgs-Chat aktualisiert',
      EDIT_ACTION: 'Bearbeiten',
      DELETE_ACTION: 'Löschen',
    },
    DEVICE_OVERVIEW: {
      HEADER: 'Geräte und Benutzer',
      DEVICE_NAME: 'Geräte-ID',
      LAST_LOGIN: 'Letzte Anmeldung',
      USER_ROLE: 'Benutzer-Rolle',
      UNBIND_TEXT: 'Benutzer de-registrieren',
      BIND_USER_ERROR: 'Benutzer nicht gefunden',
      REGISTER_DEVICE: 'Gerät registrieren',
      REGISTER_DEVICE_SUCCESS: 'Das Gerät wurde erfolgreich registriert',
      USER_ID: 'Benutzer-ID',
      ADD: 'Hinzufügen',
      DEVICE_ID: 'Geräte-ID',
      UNREGISTER_DEVICE: 'Gerät de-registrieren',
      UNREGISTER_USER: 'Benutzer de-registrieren',
      CANCEL: 'Abbrechen',
      UNREGISTER: 'Abmelden',
      UNBIND: 'Entbinden',
      ARE_YOU_SURE_DEVICE: 'Sind Sie sicher, dass Sie dieses Gerät abmelden wollen',
      ARE_YOU_SURE_USER: 'Sind Sie sicher, dass Sie die Bindung dieses Benutzers aufheben wollen',
      SEARCH_BY_USER: 'Suche',
      ALL: 'Alle',
      NORMAL: 'Normal',
      SUPERVISOR: 'Supervisor',
      ALLOWED_USERS: 'Erlaubte Benutzer',
      USERNAME: 'Name',
      SUBSCRIPTION: 'Abonnement erforderlich'
    },
    REGISTER_DEVICE: {
      CONFIRM_TEXT: 'Möchten Sie dieses Gerät registrieren',
      CONFIRM: 'Bestätigen Sie',
      CANCEL: 'Abbrechen'
    },
    EMAIL_EDIT: {
      SUBMIT: 'Einreichen',
      SEND: 'Senden Sie',
      DOWNLOAD_TEXT: 'Download E-Mail-Adressen'
    },
    SHOP: {
      SHOP_HEADING: 'Mindfield-Shop-Konto',
      NO_ACCOUNT: 'Sie haben noch kein Konto in unserem Shop erstellt oder haben keine aktiven Abonnements.',
      CREATED_AT: 'Erstellt am: ',
      CREATE_TEXT: 'Erstellen',
      NO_SUBSCRIPTIONS: 'Sie haben derzeit keine aktiven Abonnements',
      MONTH_TEXT: 'Monat',
      SUBSCRIBE_TEXT: 'Abonnieren',
      LOADING_SUBSCRIPTIONS_TEXT: 'Lade verfügbare Abonnements...',
      ACTIVE_SUBSCRIPTIONS: 'Aktive Abonnements',
      SUBSCRIBED_AT: 'Gezeichnet am: ',
      NEXT_PAYMENT: 'Nächste Zahlung: ',
      LOGIN_HEADING: 'Wenn Sie ein Konto in unserem Shop haben, geben Sie bitte Ihren Benutzernamen und Ihr Passwort ein, damit wir Ihre aktiven Abonnements abrufen können.',
      USERNAME: 'Name des Benutzers',
      PASSWORD: 'Passwort',
      LOGIN: 'Verbinden Sie',
      LOGIN_ERROR: 'FEHLER: Der eingegebene Benutzername oder das Passwort ist falsch',
      LOGOUT: 'Trennen',
      TOKEN_EXPIRES: 'Der Token läuft ab: ',
      TOKEN_EXPIRES_HINT: 'Nach diesem Datum müssen Sie sich erneut anmelden, damit wir Ihre aktiven Abonnements weiterhin erhalten können.',
      SUCCESS_CONNECT: 'Sie haben sich erfolgreich mit unserem Online-Shop verbunden.'
    }
  },
};
